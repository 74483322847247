import React, { useState } from "react";
import { Link } from "react-router-dom";

const newsTitle = "Quick Links";
const desc = "Your Winning Edge in Sports Betting! Discover expert insights, premium picks, and the strategies you need to dominate the game. Whether you're a seasoned bettor or just starting out, HitThePick delivers the tools to turn every play into a winning one.";

const Footer = () => {
    const [newsName, setNewsName] = useState('');
    const [newsEmail, setNewsEmail] = useState('');
    const baseURL = process.env.REACT_APP_BASE_URL;
    const [error, setError] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError("");
        console.log("Form submitted with email:", newsEmail);
        try {
            const response = await fetch(`${baseURL}/api/user/news-letter`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: newsName,
                    email: newsEmail,
                }),
            });
            const data = await response.json();
            if (!response.ok) {
                if (data.message) throw new Error(data.message);
                throw new Error("Something went wrong. Please try again.");
            }
            setNewsName("");
            setNewsEmail("");
            alert("You have successfully subscribed to our newsletter.");
        } catch (error) {
            setError(error.message || "Something went wrong. Please try again.");
        }
    };

    return (
        <footer className="footer-section">
            <div className="footer-middle padding-top padding-bottom" style={{backgroundImage: "url(/assets/images/footer/bg.jpg)"}}>
                <div className="container">
                    <div className="row padding-lg-top">
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="footer-middle-item-wrapper">
                                <div className="footer-middle-item mb-lg-0">
                                    <div className="fm-item-title mb-4">
                                        <img src="../../assets/images/logo/logo.png" alt="logo" width={200} />
                                    </div>
                                    <div className="fm-item-content">
                                        <p className="mb-4">{desc}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-12">
                            <div className="footer-middle-item-wrapper">
                                <div className="footer-middle-item-3 mb-lg-0">
                                    <div className="fm-item-title">
                                        <h4>{newsTitle}</h4>
                                    </div>
                                    <div className="fm-item-content">
                                        <ul>
                                            <li className="d-flex flex-column gap-2">
                                                <Link to={'/'}>Home</Link>
                                                <Link to={'/terms-of-service'}>Terms of Service</Link>
                                                <Link to={'/privacy-policy'}>Privacy Policy</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12">
                            <div className="footer-middle-item-wrapper">
                                <div className="footer-middle-item-3 mb-lg-0">
                                    <div className="fm-item-title">
                                        <h4>Contact Us</h4>
                                    </div>
                                    <div className="fm-item-content">
                                        <ul>
                                            <li className="d-flex flex-row align-items-center gap-2 mb-4">
                                                <i className="icofont-email"></i> hitthepicksports@gmail.com
                                            </li>
                                            <li className="d-flex flex-row align-items-center gap-2 mb-4">
                                                <i className="icofont-pin"></i> 2710 Conejo Canyon Ct
                                                Thousand Oaks, CA, 91362
                                            </li>
                                            <li className="d-flex flex-row align-items-center gap-2 mb-4">
                                                <i className="icofont-phone"></i> +1 (866) 566-0919
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12">
                            <div className="footer-middle-item-wrapper">
                                <div className="footer-middle-item-3 mb-lg-0">
                                    <div className="fm-item-title">
                                        <h4>Our Newsletter</h4>
                                    </div>
                                    <div className="fm-item-content">
                                        {error && <div className="error-message">{error}</div>}
                                        <form className="account-form" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={newsName}
                                                    onChange={(e) => setNewsName(e.target.value)}
                                                    placeholder="Enter your name"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={newsEmail}
                                                    onChange={(e) => setNewsEmail(e.target.value)}
                                                    placeholder="Enter your email"
                                                    required
                                                />
                                            </div>
                                            <button type="submit" className="btn btn-primary">Subscribe</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;