import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const ViewPackage = () => {
    const { id } = useParams(); // Extract the package ID from the URL
    const [packageDetails, setPackageDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem("frontend_token");
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchPackageDetails = async () => {
            try {
                if (!token) {
                    window.location.href = "/login";
                    return;
                }

                const response = await fetch(`${baseURL}/api/user/package/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch package details");
                }

                const data = await response.json();
                setPackageDetails(data[0]);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPackageDetails();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!packageDetails) {
        return <div>Package not found.</div>;
    }

    const { name, price, handicapper, picks } = packageDetails;

    return (
        <>
            <Header />
            <PageHeader title={"Package Details"} curPage={"Package Details"} />
            <section className="package-details padding-top padding-bottom">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="details-item">
                            <h2>{name}</h2>
                            <p>
                                <strong>Price:</strong> ${price}
                            </p>
                            {handicapper ? (
                                <>
                                    <p>
                                        <strong>Handicapper:</strong> {handicapper.firstname} {handicapper.lastname} (
                                        {handicapper.email})
                                    </p>
                                    <p>
                                        <strong>Handicapper Status:</strong> {handicapper.status}
                                    </p>
                                </>
                            ) : (
                                <p>No handicapper information available.</p>
                            )}

                            <h3>Picks in this Package:</h3>
                            {picks && picks.length > 0 ? (
                                <ul>
                                    {picks.map((pick) => (
                                        <li key={pick._id}>
                                            <strong>Title:</strong> {pick.title} <br />
                                            <strong>League:</strong> {pick.league} <br />
                                            <strong>Play Type:</strong> {pick.playType} <br />
                                            <strong>Analysis:</strong> {pick.analysis} <br />
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No picks are associated with this package.</p>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ViewPackage;
