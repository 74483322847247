import React from 'react';
import { Link } from 'react-router-dom';

const Success = () => {
    return (
        <div className="success-page">
            <h1>Payment Successful</h1>
            <p>Thank you for your purchase!</p>
            <Link to="/dashboard">Go to Dashboard</Link>
        </div>
    );
};

export default Success;