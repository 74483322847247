
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Swiper from 'swiper';
import 'swiper/css';
import ScrollToTop from "./component/layout/scrolltop";
import AboutPage from "./pages/about";
import AchievementPage from "./pages/achievement";
import BlogPage from "./pages/blog";
import BlogDetails from "./pages/blog-single";
import BlogPageTwo from "./pages/blogtwo";
import ContactUs from "./pages/contact";
import GalleryPage from "./pages/gallery";
import GameListSection from "./pages/gamelist";
import GameListTwoSection from "./pages/gamelisttwo";
import HomePage from './pages/home';
import HomeTwo from './pages/hometwo';
import LogIn from "./pages/login";
import PartnerPage from "./pages/partner";
import ShopPage from "./pages/shop";
import ShopCart from "./pages/shopcart";
import ShopDetails from "./pages/shopdetails";
import SignUp from "./pages/signup";
import TeamPage from "./pages/team";
import TeamSinglePage from "./pages/team-single";
import ErrorPage from "./pages/errorpage";
import ViewPick from "./pages/view-pick";
import ViewFreePick from "./pages/view-free-pick";
import ViewPackage from "./pages/view-package";
import ViewSubscription from "./pages/view-subscription";
import Dashboard from "./pages/dashboard";
import Packages from "./pages/packages";
import Subscriptions from "./pages/subscriptions";
import Picks from "./pages/picks";
import { AuthProvider } from "./context/AuthContext";
import FreePicks from "./pages/freepicks";
import TermsOfServices from "./pages/termsofservices";
import PrivacyPoliciesPage from './pages/privacypoliciespage';
import LeaderboardPage from "./pages/leaderboardpage";
import HandicapperPage from "./pages/view-handicapper";
import VerifyEmail from "./pages/verify";
import ForgotPassword from "./pages/forgotpassword";
import ResetPassword from "./pages/resetpassword";
import FAQ from "./pages/faq";
import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
import Blog1 from "./pages/blog1";
import Blog2 from "./pages/blog2";

// import Footer from "./component/layout/footer";
// import Header from "./component/layout/header";
// import PageHeader from './component/layout/pageheader';
// import GameList from './component/section/gamelist';


function App() {
	return (
		// <div className="App">
		// 	<ShopPage />
		// </div>
		<AuthProvider>
			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="about" element={<AboutPage />} />
					<Route path="*" element={<ErrorPage />} />
					<Route path="blogs" element={<BlogPage />} />
					<Route path="blog-2" element={<BlogPageTwo />} />
					<Route path="blog-single" element={<BlogDetails />} />
					<Route path="contact" element={<ContactUs />} />
					<Route path="login" element={<LogIn />} />
					<Route path="signup" element={<SignUp />} />
					<Route path="pick/:id" element={<ViewPick />} />
					<Route path="package/:id" element={<ViewPackage />} />
					<Route path="subscription/:id" element={<ViewSubscription />} />
					<Route path="dashboard" element={<Dashboard />} />
					<Route path="picks" element={<Picks />} />
					<Route path="packages" element={<Packages />} />
					<Route path="subscriptions" element={<Subscriptions />} />
					<Route path="leaderboard" element={<LeaderboardPage />} />
					<Route path="free-picks" element={<FreePicks />} />
					<Route path="free-pick/:id" element={<ViewFreePick />} />
					<Route path="terms-of-service" element={<TermsOfServices />} />
					<Route path="privacy-policy" element={<PrivacyPoliciesPage />} />
					<Route path="handicapper/:id" element={<HandicapperPage />} />
					<Route path="verify/:token" element={<VerifyEmail />} />
					<Route path="forgot-password" element={<ForgotPassword />} />
					<Route path="reset-password/:token" element={<ResetPassword />} />
					<Route path="faq" element={<FAQ />} />
					<Route path="success" element={<Success />} />
					<Route path="cancel" element={<Cancel />} />
					<Route path="blogs/why-legalizing-sports-betting-in-california-matters" element={<Blog1 />} />
					<Route path="blogs/the-evolution-of-sports-betting-in-the-usa-a-focus-on-california" element={<Blog2 />} />
				</Routes>
			</BrowserRouter>
		</AuthProvider>
	);
}

export default App;
