import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';

const HotPicksCarousel = () => {
    const [hotPicks, setHotPicks] = useState([]);

    useEffect(() => {
        const fetchHotPicks = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/hot-picks`);
            setHotPicks(response.data);
        };
        fetchHotPicks();
    }, []);

    return (
        <Carousel>
            {hotPicks.map((hotPick) => (
                <Carousel.Item key={hotPick._id}>
                    <div>
                        <h3>{hotPick.text}</h3>
                        <p>{hotPick.pick.title}</p>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default HotPicksCarousel;