import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const ViewPick = () => {
    const { id } = useParams(); // Extract id from the URL
    const [pickDetails, setPickDetails] = useState({ title: "", desc: "", btnText: "Go Home" });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchPickDetails = async () => {
            try {
                const response = await fetch(`${baseURL}/api/user/free-pick/${id}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch pick details");
                }
                const data = await response.json();
                setPickDetails(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPickDetails();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const convertToEST = (time) => {
        const date = new Date(time);
        return date.toLocaleString('en-US', { timeZone: 'America/New_York' });
    };

    return (
        <>
            <Header />
            <PageHeader title={"Pick"} curPage={"Pick"} />
            <section className="fore-zero padding-top padding-bottom">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="zero-item">
                            <div className="zero-content">
                                <h2>{pickDetails?.sportTitle}</h2><br />
                                <b>Handicapper: {pickDetails?.handicapper?.firstname + " " + pickDetails?.handicapper?.lastname}</b><br /><br />
                                <p>
                                    League: {pickDetails?.sportTitle}
                                </p>
                                <p>
                                    Home Team: {pickDetails?.homeTeam}
                                </p>
                                <p>
                                    Away Team: {pickDetails?.awayTeam}
                                </p>
                                <p>
                                    Game Time: {convertToEST(pickDetails?.matchTime)}
                                </p>
                                <p>
                                    Outcome: Take {pickDetails?.homeTeam + " " + pickDetails?.selectedOutcome?.point + " " + pickDetails?.selectedOutcome?.name + " " + pickDetails?.awayTeam}
                                </p>
                                <p>
                                    Analysis: {pickDetails?.analysis}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ViewPick;
