import React from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import '../../index.css';

const SocialMideaList = [
    {
        IconName: "icofont-instagram",
        IconLink: "#",
    },
    {
        IconName: "icofont-facebook",
        IconLink: "#",
    },
    {
        IconName: "icofont-twitter",
        IconLink: "#",
    },
];

const Header = () => {
    const navigate = useNavigate(); // React Router navigate function
    const { logout } = useAuth(); // Access logout from AuthContext

    const menuTrigger = () => {
        document.querySelector(".menu").classList.toggle("active");
        document.querySelector(".header-bar").classList.toggle("active");
    };

    const menuTriggerTwo = () => {
        document.querySelector(".header-top").classList.toggle("open");
    };

    const handleLogout = () => {
        logout(); // Call the logout function
        navigate("/"); // Navigate to the home page
    };

    React.useEffect(() => {
        const handleScroll = () => {
            const value = window.scrollY;
            const headerSection = document.querySelector(".header-section");
            if (value > 200) {
                headerSection?.classList.add("header-fixed", "fadeInUp");
            } else {
                headerSection?.classList.remove("header-fixed", "fadeInUp");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <header className="header-section">
            <div className="container">
                <div className="header-holder d-flex flex-wrap justify-content-between align-items-center">
                    <div className="brand-logo d-none d-lg-inline-block">
                        <div className="logo">
                            <Link to="/">
                                <img src="/assets/images/logo/logo.png" alt="logo" width={150} />
                            </Link>
                        </div>
                    </div>
                    <div className="header-menu-part">
                        <div className="header-top">
                            <div className="header-top-area">
                                <ul className="left">
                                    {!localStorage.getItem("frontend_token") ? (
                                        <>
                                            <li>
                                                <Link to="/login" className="login">
                                                    <i className="icofont-user"></i> <span>LOG IN</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/signup" className="signup">
                                                    <i className="icofont-users"></i> <span>SIGN UP</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li>
                                                <Link onClick={handleLogout} className="signup">
                                                    <i className="icofont-exit"></i> <span>LOGOUT</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/dashboard" className="signup">
                                                    <i className="icofont-dashboard"></i> <span>DASHBOARD</span>
                                                </Link>
                                            </li>
                                        </>
                                    )}
                                </ul>
                                <ul className="social-icons d-flex align-items-center">
                                    {SocialMideaList.map((val, i) => (
                                        <li key={i}>
                                            <a href={`${val.IconLink}`} className="fb">
                                                <i className={`${val.IconName}`}></i>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="header-bottom">
                            <div className="header-wrapper justify-content-lg-end">
                                <div className="mobile-logo d-lg-none">
                                    <Link to="/">
                                        <img src="/assets/images/logo/logo.png" alt="logo" />
                                    </Link>
                                </div>
                                <div className="menu-area">
                                    <ul className="menu">
                                        <li>
                                            <NavLink to="/">Home</NavLink>
                                        </li>
                                        <li className="dropdown">
                                            <Link to="/premium" className="dropbtn">Premium</Link>
                                            <div className="dropdown-content">
                                                <Link to="/picks">Premium Picks</Link>
                                                <Link to="/packages">Packages</Link>
                                                <Link to="/subscriptions">Subscriptions</Link>
                                            </div>
                                        </li>
                                        {/* <li>
                                            <NavLink to="/picks">Premium Picks</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/packages">Packages</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/subscriptions">Subscriptions</NavLink>
                                        </li> */}
                                        <li>
                                            <NavLink to="/free-picks">Free Picks</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/leaderboard">Leaderboard</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/blogs">Blogs</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/faq">FAQs</NavLink>
                                        </li>
                                        <li>
                                            <Link to="/signup" className="signup">
                                                <i className="icofont-fire"></i> <span>$100 free site credits</span>
                                            </Link>
                                        </li>
                                    </ul>

                                    <div className="header-bar d-lg-none" onClick={menuTrigger}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <div className="ellepsis-bar d-lg-none" onClick={menuTriggerTwo}>
                                        <i className="icofont-info-square"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;