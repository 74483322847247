import React, { useState, useEffect } from "react";
import axios from "axios";

const BannerSection = () => {
    const [matchData, setMatchData] = useState(null); // To store match details
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        // Fetch match data from the backend API
        const fetchMatchData = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/user/recent-match`);
                setMatchData(response.data);
            } catch (error) {
                console.error("Error fetching match data:", error);
            }
        };

        fetchMatchData();
    }, []); // Empty dependency array to fetch data once when the component mounts

    // If match data is not loaded yet, display a loading message
    if (!matchData) {
        return <div>Loading...</div>;
    }

    const { match, logos } = matchData;

    return (
        <section className="banner-section" style={{ backgroundImage: "url(/assets/images/banner/bg.jpg)" }}>
            <div className="container">
                <div className="banner-thumb d-flex flex-wrap justify-content-center justify-content-between align-items-center align-items-lg-end">
                    {/* Team 1 */}
                    <div className="banner-thumb-img ml-xl-50-none">
                        <img
                            src={logos.homeTeam}
                            alt={logos.homeTeam}
                            style={{ width: "300px" }}
                        />
                    </div>

                    {/* VS Image */}
                    <div className="banner-thumb-vs">
                        <img src="assets/images/banner/vs.png" alt="vs" />
                    </div>

                    {/* Team 2 */}
                    <div className="banner-thumb-img mr-xl-50-none">
                        <img
                            src={logos.awayTeam}
                            alt={logos.awayTeam}
                            style={{ width: "300px" }}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerSection;
