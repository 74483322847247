import React, { useState, useEffect } from "react";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useParams } from "react-router-dom";

const Handicapperpicks = () => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null); // Tracks the selected item for payment
    const [showPaymentModal, setShowPaymentModal] = useState(false); // Modal visibility
    const [loadingItemId, setLoadingItemId] = useState(null); // Tracks which button is loading
    const [userCredits, setUserCredits] = useState(0.0); // Tracks user's available credits
    const { id } = useParams();

    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchGameList = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/user/get-handicapper-picks/${id}`);
                setItems(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching game list:", error);
                setIsLoading(false);
            }
        };

        const fetchUserCredits = async () => {
            try {
                const userToken = localStorage.getItem("frontend_token");
                const response = await axios.get(`${baseURL}/api/user/credits`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });
                setUserCredits(parseFloat(response.data.credits) || 0);
            } catch (error) {
                console.error("Error fetching user credits:", error);
            }
        };

        fetchGameList();
        fetchUserCredits();
    }, []);

    const handleApprove = async (orderID) => {
        try {
            const userToken = localStorage.getItem("frontend_token");
            await axios.post(
                `${baseURL}/api/user/capture-order`,
                {
                    orderID,
                    type: "pick",
                    itemId: selectedItem._id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            alert("Payment successful!");
            window.location.href = "/dashboard";
        } catch (error) {
            console.error("Error capturing PayPal order:", error);
            alert("Failed to purchase.");
        } finally {
            setShowPaymentModal(false);
            setLoadingItemId(null);
        }
    };

    const handlePurchase = (item) => {
        const userToken = localStorage.getItem("frontend_token");

        if (!userToken) {
            window.location.href = "/login";
            return;
        }

        setSelectedItem(item);
        setLoadingItemId(item._id);
        setShowPaymentModal(true);
    };

    const handleCreditsPayment = async () => {
        try {
            const userToken = localStorage.getItem("frontend_token");
            const response = await axios.post(
                `${baseURL}/api/user/pay-with-credits`,
                {
                    itemId: selectedItem._id,
                    type: "pick",
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            if (response.data.success) {
                alert("Payment successful using credits!");
                window.location.href = "/dashboard";
            } else {
                alert("Failed to purchase using credits.");
            }
        } catch (error) {
            console.error("Error during credits payment:", error);
            alert("Failed to purchase using credits.");
        } finally {
            setShowPaymentModal(false);
            setLoadingItemId(null);
        }
    };

    const handleModalClose = () => {
        setShowPaymentModal(false);
        setLoadingItemId(null);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
            <section className="collection-section padding-top padding-bottom">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center collection-grid GameListStyle">
                            {items.map((item) => {
                                const { _id, title, match, handicapperId } = item;
                                return (
                                    <div className="col-12" key={_id}>
                                        <div className="match-item item-layer">
                                            <div className="match-inner">
                                                <div className="match-content bg-white">
                                                    <div className="row gy-4 align-items-center justify-content-center">
                                                        <div
                                                            className="col-xl-2 col-md-2 match-game-info d-flex flex-wrap align-items-center justify-content-start"
                                                            style={{ gap: "30px" }}
                                                        >
                                                            <img
                                                                src={baseURL + "/uploads/handicappers/" + handicapperId.profileImage}
                                                                alt={handicapperId.firstname}
                                                                style={{
                                                                    width: "100px",
                                                                    borderRadius: "50%",
                                                                    height: "100px",
                                                                    objectFit: "cover",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-xl-2 col-md-2 order-md-2">
                                                            <div className="match-game-team">
                                                                <h4 style={{ color: "black" }}>${Number(25).toFixed(2)}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-md-6 order-md-6">
                                                            <div className="match-game-info">
                                                                <h4 style={{ color: "black" }}>{title}</h4>
                                                                <p className="d-flex flex-wrap justify-content-center justify-content-md-start">
                                                                    <span className="match-date">{new Date(match?.commenceTime).toLocaleString('en-US')}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-2 col-md-2 order-md-2">
                                                            <div className="match-game-social">
                                                                <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center">
                                                                    <li>
                                                                        <button
                                                                            className="default-button reverse-effect"
                                                                            onClick={() => handlePurchase(item)}
                                                                            disabled={loadingItemId === _id}
                                                                        >
                                                                            <span>
                                                                                {loadingItemId === _id
                                                                                    ? "Loading..."
                                                                                    : "Purchase"}
                                                                            </span>
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {showPaymentModal && selectedItem && (
                    <div className="modal-overlay">
                        <div className="modal-content bg-white p-4" style={{ height: 'auto', borderRadius: '10px' }}>
                            <button className="close-button mt-1" style={{ color: 'black' }} onClick={handleModalClose}>
                                &times;
                            </button>
                            <div className="payment-options">
                                {/* Credits Payment */}
                                <div className="credits-payment">
                                    <h4 style={{ color: 'black' }}>Pay with Credits</h4>
                                    <button
                                        onClick={handleCreditsPayment}
                                        className="default-button reverse-effect mb-4"
                                        disabled={userCredits < 25}
                                    >
                                        <span>Use Credits (${userCredits.toFixed(2)} available)</span>
                                    </button>
                                </div>

                                {/* PayPal Payment */}
                                <div className="paypal-payment">
                                    <h4 style={{ color: 'black' }}>Pay with PayPal</h4>
                                    <PayPalButtons
                                        style={{
                                            layout: "vertical",
                                            color: "gold",
                                            shape: "rect",
                                            label: "paypal",
                                        }}
                                        fundingSource="paypal"
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                purchase_units: [
                                                    {
                                                        amount: {
                                                            value: (25).toFixed(2),
                                                        },
                                                    },
                                                ],
                                            });
                                        }}
                                        onApprove={(data) => handleApprove(data.orderID)}
                                        onCancel={handleModalClose}
                                        onError={(err) => {
                                            console.error("PayPal error:", err);
                                            alert("Failed to purchase.");
                                            handleModalClose();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </PayPalScriptProvider>
    );
};

export default Handicapperpicks;
