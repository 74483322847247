import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

let BlogContentListOne = [
    {
        imgUrl: 'assets/images/blog/blog-1-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: 'Why Legalizing Sports Betting in California Matters',
        desc: 'California’s stance on sports betting has become a hot topic in recent years. Despite its enormous population and sports-loving culture, the state has yet to join the growing list of states with legal sports betting.',
        btnText: 'read more',
        pubDate: '25 Jan 2025',
        blogUrl: '/blogs/why-legalizing-sports-betting-in-california-matters'
    },
    {
        imgUrl: 'assets/images/blog/blog-2-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: 'The Evolution of Sports Betting in the USA: A Focus on California',
        desc: 'Sports betting has experienced an unprecedented rise in popularity across the United States in recent years, driven by legislative changes and evolving public attitudes.',
        btnText: 'read more',
        pubDate: '25 Jan 2025',
        blogUrl: '/blogs/the-evolution-of-sports-betting-in-the-usa-a-focus-on-california'
    },
]

class BlogPage extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'OUR BLOG POSTS'} curPage={'Blog'} />
                <div className="blog-section padding-top padding-bottom">
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="row g-4 justify-content-center">
                                {BlogContentListOne.map((val, i) => (
                                    <div className="col-12" key={i}>
                                        <div className="blog-item">
                                            <div className="blog-inner d-flex flex-wrap align-items-center">
                                                <div className="blog-thumb w-xl-50 w-100">
                                                    <Link to={`${val.blogUrl}`}>
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} className="w-100" />
                                                    </Link>
                                                </div>
                                                <div className="blog-content p-4 w-xl-50 w-100">
                                                    <Link to={`${val.blogUrl}`}><h3>{val.title}</h3></Link>
                                                    <div className="meta-post">
                                                        <a href="#">{val.pubDate}</a>
                                                    </div>
                                                    <p>{val.desc}</p>
                                                    <Link to={`${val.blogUrl}`} className="default-button"><span>{val.btnText} <i className="icofont-circled-right"></i></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}
 
export default BlogPage;