import React, { useState, useEffect } from "react";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SubscriptionList = () => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null); // Tracks the selected subscription for payment
    const [showPaymentModal, setShowPaymentModal] = useState(false); // Modal visibility
    const [loadingItemId, setLoadingItemId] = useState(null); // Tracks which button is loading
    const [userCredits, setUserCredits] = useState(0.0); // Tracks user's available credits

    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/user/get-subscriptions`);
                setItems(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching subscriptions list:", error);
                setIsLoading(false);
            }
        };

        const fetchUserCredits = async () => {
            try {
                const userToken = localStorage.getItem("frontend_token");
                const response = await axios.get(`${baseURL}/api/user/credits`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });
                setUserCredits(parseFloat(response.data.credits) || 0);
            } catch (error) {
                console.error("Error fetching user credits:", error);
            }
        };

        fetchSubscriptions();
        fetchUserCredits();
    }, []);

    const handlePurchase = (item) => {
        const userToken = localStorage.getItem("frontend_token");

        if (!userToken) {
            window.location.href = "/login";
            return;
        }
        setSelectedItem(item);
        setLoadingItemId(item._id);
        setShowPaymentModal(true);
    };

    const handleCreditsPayment = async () => {
        try {
            const userToken = localStorage.getItem("frontend_token");
            const response = await axios.post(
                `${baseURL}/api/user/pay-with-credits`,
                {
                    itemId: selectedItem._id,
                    type: "subscription",
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            if (response.data.success) {
                alert("Payment successful using credits!");
                window.location.href = "/dashboard";
            } else {
                alert("Failed to purchase using credits.");
            }
        } catch (error) {
            console.error("Error during credits payment:", error);
            alert("Failed to purchase using credits.");
        } finally {
            setShowPaymentModal(false);
            setLoadingItemId(null);
        }
    };

    const handleApprove = async (orderID) => {
        try {
            const userToken = localStorage.getItem("frontend_token");

            await axios.post(
                `${baseURL}/api/user/capture-order`,
                {
                    orderID,
                    type: "subscription",
                    itemId: selectedItem._id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            alert("Payment successful!");
            window.location.href = "/dashboard";
        } catch (error) {
            console.error("Error capturing PayPal order:", error);
            alert("Failed to purchase.");
        } finally {
            setShowPaymentModal(false);
            setLoadingItemId(null);
        }
    };

    const handleModalClose = () => {
        setShowPaymentModal(false);
        setLoadingItemId(null);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
            <section className="collection-section padding-top padding-bottom">
                <div className="container">
                    <div className="section-header">
                        <h2>Subscription Plans</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center collection-grid GameListStyle">
                            {items.map((item) => {
                                const { _id, name, handicapper, price } = item;
                                return (
                                    <div className="col-12" key={_id}>
                                        <div className="match-item item-layer">
                                            <div className="match-inner">
                                                <div className="match-content bg-white">
                                                    <div className="row gy-4 align-items-center justify-content-center">
                                                        <div
                                                            className="col-xl-2 col-md-2 match-game-info d-flex flex-wrap align-items-center justify-content-start"
                                                            style={{ gap: "30px" }}
                                                        >
                                                            <img
                                                                src={baseURL + "/uploads/handicappers/" + handicapper[0].profileImage}
                                                                alt={handicapper[0].firstname}
                                                                style={{
                                                                    width: "100px",
                                                                    borderRadius: "50%",
                                                                    height: "100px",
                                                                    objectFit: "cover",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-xl-4 col-md-4 order-md-2">
                                                            <div className="match-game-team">
                                                                <ul className="match-team-list d-flex flex-wrap align-items-center justify-content-center">
                                                                    <li className="match-team-thumb">
                                                                        <h4 style={{ color: "black" }}>${Number(price).toFixed(2)}</h4>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-6 order-md-1">
                                                            <div className="match-game-info">
                                                                <h4 style={{ color: "black" }}>{name}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-2 col-md-6 order-md-3">
                                                            <div className="match-game-social">
                                                                <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center">
                                                                    <li>
                                                                        <button
                                                                            className="default-button reverse-effect"
                                                                            onClick={() => handlePurchase(item)}
                                                                            disabled={loadingItemId === _id}
                                                                        >
                                                                            <span>
                                                                                {loadingItemId === _id
                                                                                    ? "Loading..."
                                                                                    : "Purchase"}
                                                                            </span>
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {/* Payment Modal */}
                {showPaymentModal && selectedItem && (
                    <div className="modal-overlay">
                        <div className="modal-content bg-white p-4" style={{ height: 'auto', borderRadius: '10px' }}>
                            <button className="close-button mt-1" style={{ color: 'black' }} onClick={handleModalClose}>
                                &times;
                            </button>
                            <div className="payment-options">
                                {/* Credits Payment */}
                                <div className="credits-payment">
                                    <h4 style={{ color: 'black' }}>Pay with Credits</h4>
                                    <button
                                        onClick={handleCreditsPayment}
                                        className="default-button reverse-effect mb-4"
                                        disabled={userCredits < selectedItem.price}
                                    >
                                        <span>Use Credits (${userCredits.toFixed(2)} available)</span>
                                    </button>
                                </div>

                                {/* PayPal Payment */}
                                <div className="paypal-payment">
                                    <h4 style={{ color: 'black' }}>Pay with PayPal</h4>
                                    <PayPalButtons
                                        style={{
                                            layout: "vertical",
                                            color: "gold",
                                            shape: "rect",
                                            label: "paypal",
                                        }}
                                        fundingSource="paypal"
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                purchase_units: [
                                                    {
                                                        amount: {
                                                            value: Number(selectedItem.price).toFixed(2),
                                                        },
                                                    },
                                                ],
                                            });
                                        }}
                                        onApprove={(data) => handleApprove(data.orderID)}
                                        onCancel={handleModalClose}
                                        onError={(err) => {
                                            console.error("PayPal error:", err);
                                            alert("Failed to purchase.");
                                            handleModalClose();
                                        }}
                                    />
                                </div>

                                {/* Stripe Payment */}
                                {/* <div className="stripe-payment">
                                    <h4 style={{ color: 'black' }}>Pay with Stripe</h4>
                                    <Elements stripe={stripePromise}>
                                        <StripeCheckout
                                            selectedItem={selectedItem}
                                            baseURL={baseURL}
                                            handleModalClose={handleModalClose}
                                        />
                                    </Elements>
                                </div> */}
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </PayPalScriptProvider>
    );
};

const StripeCheckout = ({ selectedItem, baseURL, handleModalClose }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const userToken = localStorage.getItem("frontend_token");

        try {
            const { data: { sessionId } } = await axios.post(
                `${baseURL}/api/user/create-checkout-session`,
                {
                    itemId: selectedItem._id,
                    type: 'subscription',
                    amount: selectedItem.price * 100, // Convert to cents
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            const stripe = await stripePromise;
            await stripe.redirectToCheckout({ sessionId });
        } catch (error) {
            console.error("Error processing payment:", error);
            alert("Failed to purchase.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="stripe-form mt-4">
            <CardElement options={{ style: { base: { fontSize: "16px" } } }} />
            <button type="submit" className="default-button mt-4" disabled={loading}>
                <span>{loading ? "Processing..." : "Pay Now"}</span>
            </button>
        </form>
    );
};

export default SubscriptionList;
